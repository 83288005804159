<template>
    <div class="user-profile fade-in">
        <div class="model-header" style="font-size:24px;color:var(--gold);">פרופיל ארגון</div>
        <div class="input-label">
            <p>שם הארגון</p>
        </div>
        <div class="input-field">
            <input type="text" name="name" id="name" class="txt-input" v-model="name" @change="checkName">
        </div>
        
        <div class="input-label">
            <p>ח.פ / מזהה ארגון</p>
        </div>
        <div class="input-explainer">
            <span class="material-symbols-rounded">contact_support</span>
            <p>יישמש לזיהוי הארגון וצירוף עובדים חדשים.</p>
        </div>
        <div class="input-field">
            <input type="number" name="org_id" id="org_id" class="txt-input" v-model="org_id" @change="checkOrgId">
        </div>

        <div class="input-label">
            <p>תחום עיסוק</p>
        </div>
        <div class="input-explainer">
            <span class="material-symbols-rounded">contact_support</span>
            <p>המערכת תתאים כלים ותצוגות בהתאם לתחום הנבחר.</p>
        </div>
        <div class="input-field">
            <input list="select-sector" name="sector" id="sector" class="txt-input" placeholder="בחר מרשימה" autocomplete="off"  @click="sector = null" v-model="sector" @change="checkSector">
            <datalist id="select-sector">
                <template v-for="sectorx in sectors" :key="sectorx">
                    <option :value="sectorx" @select="toggle_input('city')">{{sectorx}}</option>
                </template>
            </datalist>
        </div>

        <div class="input-label">
            <p>עיר</p>
        </div>
        <div class="input-field">
            <input list="select-city" name="city" id="city" class="txt-input" placeholder="בחר מרשימה" autocomplete="off" v-model="city" @change="get_streets">
            <datalist id="select-city">
                <template v-for="cityx in cities" :key="cityx">
                    <option :value="cityx.trim()">{{cityx}}</option>
                </template>
            </datalist>
        </div>

        <div class="input-label">
            <p>רחוב</p>
        </div>
        <div class="input-field">
            <input list="select-street" name="street" id="street" class="txt-input" placeholder="בחר מרשימה" autocomplete="off" v-model="adress">
            <datalist id="select-street">
                <template v-for="street in streets" :key="street">
                    <option :value="street">{{street}}</option>
                </template>
            </datalist>
        </div>

        <div class="input-label">
            <p>מספר</p>
        </div>
        <div class="input-field">
            <input type="number" name="adress_num" id="adress_num" class="txt-input" v-model="adress_num" @change="checkStreetNum">
        </div>


        <button class="success-btn" style="width:100%; max-width:400px; margin-top:25px;" @click="continue_next">
            <span>המשך</span>
        </button>
        <button class="select-btn grey" style="width:100%; max-width:400px; margin-top:10px;" @click="go_back">
            <span>חזור</span>
        </button>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { computed, onMounted, watch } from '@vue/runtime-core';
import store from '../../../../store';
import router from '../../../../router';
import { get_streets_by_city_api, get_citys_from_api } from '../../../../Scripts/AdressAPI';
import { slide_pop_error } from '../../../../Scripts/Msgs';

export default {
setup(){
    
    const user = ref(computed(() => {
        return store.state.user
    }))

    const name = ref(null);
    const org_id= ref(null);
    const sector = ref(null);
    const city = ref(null);
    const adress = ref(null);
    const adress_num = ref(null);

    const cities = ref(null);
    const streets_api = ref(null);

    const sectors = ref([
        "מחשוב",
        "מקצועות חופשיים",
        "תוכנה",
        "משחקים",
        "אינטרנט",
        "נדלן",
        "שירותים",
        "רכב",
        "מזון",
        "מזון מהיר",
        "חיי לילה",
        "בעלי חים",
        "הובלות",
        "חנויות ומרכולים",
        "אי-קומרס",
        "בידור",
        'מלכ"ר',
        "תעשיה ומלאכה",
        "אחר"
    ])

    const streets = ref(computed(() => {
        if(city.value){
            return streets_api.value
        }
        return []
    }))

    const get_streets = async () => {
        if(city.value){
            adress.value = null;
            streets_api.value = await get_streets_by_city_api(city.value.trim())
        }
    }

    const toggle_input = (name) => {
        let el = document.getElementById(name);
        el.focus();
    }

    const get_cities = async () =>{
        cities.value = await get_citys_from_api();
    }

    const checkName = () => {
        if(!name.value || name.value.length < 3 || name.value.split < 2){
            slide_pop_error("אנא מלא שם מלא ותקני", 3000)
            toggle_input('name');
            return false
        }
        return true
    }

    const checkOrgId = () => {
        if(!org_id.value || String(org_id.value).length < 4){
            slide_pop_error("אנא מלא מזהה ארגון מלא ותקני", 3000)
            toggle_input('org_id');
            return false
        }
        return true
    }

    const checkStreet = () => {
        if(!adress.value ){
            slide_pop_error("אנא בחר רחוב", 3000)
            toggle_input('street');
            return false
        }
        return true
    }

    const checkSector = () => {
        if(!sector.value ){
            slide_pop_error("אנא בחר תחום עיסוק", 3000)
            toggle_input('sector');
            return false
        }
        return true
    }

    const checkCity = () => {
        if(!city.value ){
            slide_pop_error("אנא בחר עיר", 3000)
            toggle_input('city');
            return false
        }
        return true
    }

    const checkStreetNum = () => {
        if(!adress_num.value ){
            slide_pop_error("אנא מלא מספר רחוב מגורים", 3000)
            toggle_input('adress_num');
            return false
        }
        return true
    }

    const continue_next = () => {
        if(!checkName()){
            return
        }
        if(!checkOrgId()){
            return
        }
        if(!checkSector()){
            return
        }
        if(!checkCity()){
            return
        }
        if(!checkStreet()){
            return
        }
        if(!checkStreetNum()){
            return
        }
        store.state.temp_obj.org_name = name.value;
        store.state.temp_obj.org_id = org_id.value;
        store.state.temp_obj.sector = sector.value;
        store.state.temp_obj.city = city.value;
        store.state.temp_obj.adress = adress.value;
        store.state.temp_obj.adress_num = adress_num.value;

        router.push('setupneworg3')
    }

    const go_back = () => {
        router.push('setupneworg')
    }

    onMounted(() => {
        get_cities();
        if(store.state.temp_obj && store.state.temp_obj.model == 'neworg' && store.state.temp_obj.org_name){
            name.value = store.state.temp_obj.org_name;
            org_id.value = store.state.temp_obj.org_id;
            sector.value = store.state.temp_obj.sector;
            city.value = store.state.temp_obj.city;
            adress.value = store.state.temp_obj.adress;
            adress_num.value = store.state.temp_obj.adress_num;
        }
    })

    return{
        user, name, org_id, sector, city, adress, adress_num, 
        continue_next, go_back, get_streets,
        cities, streets, sectors,
        checkName, checkOrgId, checkSector,
        toggle_input
    }
}
}
</script>

<style scoped>
.user-profile{
    width: 100%;
    max-width: 400px;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    margin: auto;
    padding: 80px 5px 0 5px;
}
datalist option {
    font-size: 36px;
    padding: 0.3em 1em;
    background-color: red;
    cursor: pointer;
}

</style>